// @flow

import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { marked } from 'marked';
import { BrowserSupport } from '@latitude/browser-support';
import { Footer } from '@latitude/footer';
import { BrokerHeader } from './BrokerHeader';
import GemHeader from './Header/GemHeader';
import footerContent from '../data/json/footer.json';
import brokerHeaderData from '../data/pages/brokers/broker-header';
import { PageContext } from '../context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import Alert from '@/components/lab-components/Alert';
import { selectDataSourceForAlertBanner } from '@/utils/helpers';
import '../../sass/style.scss';

const Layout = (props) => {
  const pageData = useContext(PageContext);
  const [footerData, setFooterData] = useState(footerContent);
  const [alertData, setAlertData] = useState();

  /** Contentful Global Components in Template */
  useEffect(() => {
    const footerComponent = pageData?.template?.footer;
    setAlertData(selectDataSourceForAlertBanner(pageData));
    // Check if the page entry in Contentful contains Footer
    if (footerComponent) {
      // If yes, use Contentful data
      const updatedFooterComponent = {
        ...footerComponent,
        finePrint:
          footerComponent?.finePrint && marked(footerComponent?.finePrint),
        footerLinks: footerComponent?.footerLinks?.links,
        appLinks:
          (footerComponent?.appLinks && {
            downloadLabel:
              footerComponent?.appLinks?.header &&
              marked(footerComponent?.appLinks?.header),
            appleLink: footerComponent?.appLinks?.links?.filter(
              link => link?.label === 'Apple Link'
            )?.[0]?.link,
            googleLink: footerComponent?.appLinks?.links?.filter(
              link => link?.label === 'Google Link'
            )?.[0]?.link
          }) ||
          undefined,
        socialLinks: footerComponent?.socialLinks?.links?.map(item => {
          if (item.icon === 'xtwitter') {
            return { ...item, icon: 'twitter' };
          } else return item;
        }),
        siteLinks: footerComponent?.siteLinks?.map(siteLink => ({
          header: siteLink?.header,
          menuItems: siteLink?.links?.map(link => ({
            label: link?.label,
            link: link?.link
          }))
        })),
        cardData: {
          text: footerComponent?.cardDataText,
          href: footerComponent?.cardDataUrl,
          img: footerComponent?.cardDataImage?.file?.url
        }
      };
      setFooterData(updatedFooterComponent);
    } else {
      // If no, fall back to hard-coded JSON
      if (props.customFooter) {
        // Use custom Footer if set
        setFooterData(props.customFooter);
      }
    }
  },[pageData])
  /** - END - */

  return (
    <React.Fragment>
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />

        {/* <!-- Google Fonts --> */}
        <link
          href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,500,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,800,600&display=swap"
          rel="stylesheet"
        />

        <script
          type="text/javascript"
          src="https://www.bugherd.com/sidebarv2.js?apikey=k8x4tdkkpaylbl47h1vpyq"
          async="true"
        />
      </Helmet>

      <BrowserSupport />

      {!props.noHeader && <GemHeader />}
      {props.brokerHeader && <BrokerHeader headerData={brokerHeaderData} />}
      {alertData && (
        <div className={`alert-offset ${props.hasSecNav ? "alert-offset--spacer" : ""} ${props.hasStickyNav ? "alert-offset--mobile" : ""}`}>
          <Alert {...alertData} />
        </div>
      )}

      {props.children}

      {!props.noFooter && (
        <Footer
          socialLinks={footerData?.socialLinks || []}
          siteLinks={footerData?.siteLinks || []}
          appLinks={footerData?.appLinks || undefined}
          footerLinks={footerData?.footerLinks || []}
          finePrint={footerData?.finePrint}
          isNz
        />
      )}
    </React.Fragment>
  );
};

export default Layout;
