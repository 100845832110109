// @flow

export const SITE_URL = 'https://www.gemfinance.com.au';

// loan types
export const LOAN_TYPES = Object.freeze({
  PL: 'PL', // personal loan
  CL: 'CL', // car loan
  LRPL: 'LRPL' // low rate personal loan
});

// Loan payment frequency
export const LOAN_PAY_FREQUENCY = Object.freeze({
  WEEKLY: 'WEEKLY',
  FORTNIGHTLY: 'FORTNIGHTLY',
  MONTHLY: 'MONTHLY'
});

/**
 * Personal Loan constants
 *
 * Notes:
 * - 'PL_MONTHLY_FEE': LM2-244 - NZ has 0 fee
 */
export const PL_MINIMUM_AMOUNT = 2000;
export const PL_BROKER_MINIMUM_AMOUNT = 4000;
export const PL_SYMPLE_DEFAULT_WIDGET_AMOUNT = 30000;
export const PL_MAXIMUM_AMOUNT = 70000;
export const PL_SECURED_MIN_IR = 8.99;
export const PL_SECURED_MAX_IR = 24.99;
export const PL_UNSECURED_MIN_IR = 10.99;
export const PL_UNSECURED_MAX_IR = 25.99;
export const PL_VARIABLE_SYMPLE_MIN_IR = 8.99;
export const PL_VARIABLE_SYMPLE_MAX_IR = 29.99;
export const PL_FIXED_SYMPLE_MIN_IR = 8.99;
export const PL_FIXED_SYMPLE_MAX_IR = 29.99;
export const PL_MONTHLY_FEE = 0; // LM2-244 - NZ has 0 fee
export const PL_SETTLEMENT_FEE = 240;
export const PL_DEFAULT_LOAN_TERM = 7;
export const PL_MAX_LOAN_TERM = 7;
export const PL_TEL = '0800 422 898';
export const PL_APPLY_URL = '/loans/apply/';
export const PL_APPLY_CTA_TRACK_ID = 'pl-get-my-rate';
export const MOTOR_LOAN_APPLY_URL = '/loans/apply/';

/**
 * Car Loan constants
 *
 * Notes:
 * - 'PL_MONTHLY_FEE': LM2-244 - NZ has 0 fee
 * - 'CL_DEFAULT_LOAN_TERM': LM2-227 - confirmed by NZ to use same value as PL
 * - 'CL_MAX_LOAN_TERM': LM2-227 - confirmed by NZ to use same value as PL
 */
export const CL_MINIMUM_AMOUNT = PL_MINIMUM_AMOUNT;
export const CL_MAXIMUM_AMOUNT = PL_MAXIMUM_AMOUNT;
export const CL_SECURED_MIN_IR = PL_SECURED_MIN_IR;
export const CL_SECURED_MAX_IR = PL_SECURED_MAX_IR;
export const CL_MONTHLY_FEE = 0;
export const CL_SETTLEMENT_FEE = 240;
export const CL_DEFAULT_LOAN_TERM = PL_DEFAULT_LOAN_TERM;
export const CL_MAX_LOAN_TERM = PL_MAX_LOAN_TERM;
export const CL_TEL = PL_TEL;
export const CL_APPLY_URL = PL_APPLY_URL;
export const CL_APPLY_CTA_TRACK_ID = 'cl-get-my-rate';

// QA: 'https://origqa-gecapitalau.cs5.force.com/gem', // TODO: Revert to this value once SF Test is updated
export const FUNNEL_URL = Object.freeze({
  PL: {
    DEV: 'https://origdev-gecapitalau.cs74.force.com/gem',
    QA: 'https://origdev-gecapitalau.cs74.force.com/gem',
    PROD: 'https://lfs-apply.mypersonalloanapp.com/gem'
  },
  SQ: {
    DEV:
      'https://test01.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com',
    DEV_LOANS: 'https://latitudefinancialsnz--uatnz.sandbox.my.site.com',
    QA:
      'https://test01.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com',
    QA_LOANS: 'https://latitudefinancialsnz--uatnz.sandbox.my.site.com',
    PROD: 'https://quote.gemfinance.co.nz',
    PROD_LOANS: 'https://loans.portal.gemfinance.co.nz'
  },
  EAPPS: {
    DEV:
      'https://onlinepaymentintegration-sit.latitudefinancial.com/eapps/faces/LongAppRetail/personal.xhtml?cardType=gemvisanz',
    QA:
      'https://onlinepaymentintegration-sit.latitudefinancial.com/eapps/faces/LongAppRetail/personal.xhtml?cardType=gemvisanz',
    PROD:
      'https://apply.gemvisa.co.nz/eapps/ApplyRetail.faces?cardType=gemvisanz'
  },
  OSC: {
    DEV: 'https://master.servicecentre-nz.digitalservicing-np.lfscnp.com/login',
    QA: 'https://master.servicecentre-nz.digitalservicing-np.lfscnp.com/login',
    PROD: 'https://servicecentre.latitudefinancial.co.nz/login'
  },
  SQ_BROKER: {
    // TODO: Broker DEV and QA URLS to be confirmed by loans April 2023
    DEV: 'https://broker.portal.gemfinance.co.nz/',
    QA: 'https://broker.portal.gemfinance.co.nz/',
    PROD: 'https://broker.portal.gemfinance.co.nz/'
  }
});

export const LOAN_PURPOSE = Object.freeze({
  SELECT_PURPOSE: 'purpose',
  AUTO_PURCHASE: 'auto-purchase',
  CARAVAN_BOAT_RECREATIONAL: 'caravan-boat-recreational',
  EDUCATION: 'education',
  HOUSEHOLD_FURNISHINGS: 'household-furnishings',
  AUTO_REPAIRS: 'auto-repairs',
  CONSOLIDATION: 'consolidation',
  HOME_IMPROVEMENTS: 'home-improvements',
  MEDICAL_DENTAL: 'medical-dental',
  TRAVEL_HOLIDAY: 'travel-holiday',
  OTHER: 'other'
});

export const ALIGN = Object.freeze({
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
  JUSTIFY: 'justify'
});
export const BREAKPOINT = Object.freeze({
  XS: '380px',
  SM: '576px',
  MD: '768px',
  LG: '992px',
  LG_DOWN: '991px',
  XL: '1200px'
});

export const COLOR = Object.freeze({
  BLUE: '#006AFF',
  BLUE_BABY: '#E8F4FF',
  BLUE_SKY: '#D0E9FF',
  BLUE_LIGHT: '#63B8FF',
  BLUE_LIGHT_BRIGHT: '#CEE6FF',
  BLUE_MID: '#004A95', // #OP-558
  BLUE_DEEP: '#0046AA',
  BLUE_DARK: '#00366c',
  PURPLE: '#ABA7F6',
  PURPLE_LIGHT: '#E5E4FC',
  PURPLE_DARK: '#7A65D4',
  PINK: '#C31B6C',
  PINK_LIGHT: '#FF94CA',
  PINK_BABY: '#FFDEEF',
  TURQUOISE: '#12DBF5',
  BODY: '#42484d',
  WHITE: '#FFFFFF',
  GREY4: '#F1F2F3',
  GREY5: '#F3F3F3',
  GREY6: '#F8F8F8',
  GREY10: '#E5E5E5',
  GREY12: '#ECECEC',
  GREY16: '#D6D6D6',
  GREY65: '#595959',
  GREY75: '#414141',
  BLACK: '#000000',
  TEXT_ERROR: '#B3000C',
  TRANSPARENT: 'transparent',
  ERROR: 'red'
});

export const MARGIN = Object.freeze({
  TEXT: '16px',
  MAUTO: 'auto',
  M0: '0px',
  M4: '4px',
  M8: '8px',
  M16: '16px',
  M24: '24px',
  M32: '32px',
  M40: '40px',
  M48: '48px',
  M56: '56px',
  M64: '64px'
});

export const PADDING = Object.freeze({
  P0: '0px',
  P4: '4px',
  P8: '8px',
  P16: '16px',
  P24: '24px',
  P32: '32px',
  P40: '40px',
  P48: '48px',
  P56: '56px',
  P64: '64px',
  P72: '72px'
});

export const POSITION = Object.freeze({
  STATIC: 'static',
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
  FIXED: 'fixed'
});

export const FONT_FAMILY = Object.freeze({
  BODY: 'Roboto, Open Sans, sans-serif',
  HEADING: 'Roboto Slab, sans-serif',
  TITLE: 'Montserrat, sans-serif'
});

export const FONT_SIZE = Object.freeze({
  SMALL: '14px',
  NORMAL: '16px',
  LARGE: '18px'
});

export const FONT_WEIGHT = Object.freeze({
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800
});

export const HEADING = Object.freeze({
  hero: {
    FONT_SIZE: {
      NORMAL: '48px',
      LARGE: '104px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '48px',
      LARGE: '120px'
    }
  },
  h1: {
    FONT_SIZE: {
      NORMAL: '48px',
      LARGE: '48px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '56px',
      LARGE: '56px'
    }
  },
  h2: {
    FONT_SIZE: {
      NORMAL: '40px',
      LARGE: '40px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '48px',
      LARGE: '48px'
    }
  },
  h3: {
    FONT_SIZE: {
      NORMAL: '32px',
      LARGE: '40px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '36px',
      LARGE: '48px'
    }
  },
  h4: {
    FONT_SIZE: {
      NORMAL: '24px',
      LARGE: '32px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '28px',
      LARGE: '36px'
    }
  },
  h5: {
    FONT_SIZE: {
      NORMAL: '20px',
      LARGE: '20px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '24px',
      LARGE: '32px'
    }
  },
  h6: {
    FONT_SIZE: {
      NORMAL: '16px',
      LARGE: '16px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '22px',
      LARGE: '22px'
    }
  }
});

export const INPUT_PADDING = Object.freeze({
  // SMALL: '14px',
  NORMAL: '0.75em 1em',
  // LARGE: '18px'
  WIDGET: '0.25em 0em'
});

export const LINE_HEIGHT = Object.freeze({
  STANDARD: {
    SMALL: '24px',
    NORMAL: '26px',
    LARGE: '28px'
  },
  TIGHT: {
    SMALL: '18px',
    NORMAL: '20px',
    LARGE: '24px'
  }
});

export const CALCULATOR_TRACKING = Object.freeze({
  CATEGORY: 'calculator',
  ACTION: 'interaction',
  LOCATION: 'Calculate your repayments',
  LABEL: "I'd like to borrow"
});

// Soft Quote
export const SOFT_QUOTE_NZ_URL = '/nz/';
export const SOFT_QUOTE_NZ_RETRIEVE_URL = '/nz/retrieve';
export const SOFT_QUOTE_LOANS_NZ_URL = '/s/quote';
export const SOFT_QUOTE_LOANS_NZ_RETRIEVE_URL = '';
export const SOFT_QUOTE_BROKER_URL = '';
export const SOFT_QUOTE_RETRIEVE_URL =
  'https://loans.portal.gemfinance.co.nz/s/';
export const ALIGN_ITEMS = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  STRETCH: 'stretch',
  BASELINE: 'baseline'
});

/*
 * Latitude App
 */

export const APP_STORE = Object.freeze({
  APPLE: 'apple',
  GOOGLE: 'google'
});

export const GUTTER = Object.freeze({
  G0: '0px',
  G15: '15px',
  G30: '30px'
});

export const ICON_VARIANT = Object.freeze({
  ABOUT_YOU: 'about-you',
  APPLY_ONLINE_BRAND: 'apply-online-branded',
  ATTENTION: 'attention',
  BILLS: 'bills',
  BOAT: 'boat',
  CALCULATOR: 'calculator',
  CALL_US_BRAND: 'call-us-branded',
  CAR: 'car',
  CARAVAN: 'caravan',
  CHAT: 'chat',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_UP: 'chevron-up',
  CIRCLE_QUESTION: 'circle-question',
  CIRCLE_TICK: 'circle-tick',
  COCKTAIL: 'cocktail',
  DANCE_STEPS: 'dance-steps',
  DEVICES: 'devices',
  ENVELOPE: 'envelope',
  HAND_DOLLAR: 'hand-dollar',
  FACE_ID: 'face-id',
  IPHONE: 'iphone',
  LIGHT_TRUCK: 'light-truck',
  LIST: 'list',
  LOAN: 'loan',
  MENU: 'menu',
  MONEY_BUNDLE: 'money-bundle',
  MOTORBIKE: 'motorbike',
  NODE: 'node',
  PHONE: 'phone',
  PLANE: 'plane',
  RELATIONSHIP: 'relationship',
  SHIELD_DOLLAR: 'shield-dollar',
  SHIELD_PADLOCK: 'shield-padlock',
  SLINKY: 'slinky',
  SPEED: 'speed',
  STOPWATCH: 'stopwatch',
  THUMBS_UP: 'thumbs-up',
  TROPHY: 'trophy',
  TICK_RESPONSE: 'response-tick'
});

export const ICON_SIZE = Object.freeze({
  XS: '16px',
  SM: '32px',
  MD: '48px',
  LG: '64px',
  XL: '96px'
});

export const BUTTON_TYPE = Object.freeze({
  BUTTON: 'button',
  RESET: 'reset',
  SUBMIT: 'submit'
});

export const BUTTON_STYLE = Object.freeze({
  PRIMARY: 'primary',
  PRIMARY_INVERSE: 'primaryInverse',
  SECONDARY: 'secondary',
  SECONDARY_INVERSE: 'secondaryInverse',
  TERTIARY: 'tertiary',
  TERTIARY_INVERSE: 'tertiaryInverse'
});

export const JUSTIFY_CONTENT = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  SPACE_BETWEEN: 'space-between',
  SPACE_AROUND: 'space-around',
  SPACE_EVENLY: 'space-evenly'
});

export const FLEX_DIRECTION = Object.freeze({
  ROW: 'row',
  ROW_REVERSE: 'row-reverse',
  COLUMN: 'column',
  COLUMN_REVERSE: 'column-reverse'
});

export const SPAN = Object.freeze({
  '1 Col': 12,
  '2 Cols': 6
});

export const TEXT_TYPE = Object.freeze({
  'Heading 1': 'h1',
  'Heading 2': 'h2',
  'Heading 3': 'h3',
  'Heading 4': 'h4'
});

export const CONTENTFUL_COMPONENTS = Object.freeze({
  FIND_AN_OFFER_SECTION: 'componentsOffers',
  FIND_BY_CATEGORY_SECTION: 'componentsOfferCategory',
  HERO_BANNER_CAROUSEL: 'componentsHeroBannerCarousel',
  FEATURE_SLIDER: 'componentsFeatureSlider',
  PROMO_BANNER: 'componentsPromoBanner',
  ALERT: 'componentsAlert'
})